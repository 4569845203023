<template>
  <!-- v-touch="{
      left: () => $router.go(1),
      right: () => $router.push(-1)
    }" -->
  <v-row
    align="center"
  >
    <v-col>
      <app-card>
        <v-card-text>
          <h2>
            {{ $t("message.q&a_1") }}
          </h2>

          <material-alert
            color="info"
            dark
          >
            <h3>{{ $t("message.q&a_2q") }}</h3>
          </material-alert>
          <h4>
            {{ $t("message.q&a_2a") }}
          </h4>

          <material-alert
            color="info"
            dark
          >
            <h3>{{ $t("message.q&a_3q") }}</h3>
          </material-alert>
          <h4>
            {{ $t("message.q&a_3a") }}
          </h4>

          <material-alert
            color="info"
            dark
          >
            <h3>{{ $t("message.q&a_4q") }}</h3>
          </material-alert>
          <h4>
            {{ $t("message.q&a_4a") }}
          </h4>
        </v-card-text>
      </app-card>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'NotificationsView',
    metaInfo: {
      title: 'Help',
      titleTemplate: '%s | FFXIV Housing Eden',
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'keywords', content: 'ff14 ハウジング,ハウジング ff14,ff14 housing,housing ff14,ff14ハウジング,ff14housing,ハウジング,ハウジングエデン,ff14,ファイナルファンタジー,housing,ffxiv,final fantasy,Housing Eden,housing eden' },
        { property: 'og:type', content: 'website' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { property: 'og:url', content: 'https://e-d-e-n.site' },
        { property: 'og:title', content: 'FFXIV Housing Eden' },
        { name: 'description', content: 'FFXIV Housing Edenはファイナルファンタジー14の最新ハウジング情報を世界レベルでお届けするサイトです。今話題のツイートはもちろん、バズツイート、ハウジンガー名鑑、創作家具レシピまで網羅しています。' },
        { property: 'og:image', content: 'https://e-d-e-n.site/twitter_card.jpg?3' },
      ],
    },
    data () {
      return {
        // sns拡散
        sns: {
          twitter: 'https://twitter.com/intent/tweet?hashtags=HousingEden',
        },
      }
    },
  }
</script>
